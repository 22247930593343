<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='name'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`name.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
                cols='12'
                xl='6'
                md='6'
            >
              <validation-provider
                  v-slot='{ errors }'
                  vid='services'
                  :name="$t('services')"
              >
                <b-form-group
                    :label="$t('services')"
                >
                  <v-select
                      v-model='form.services'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='services'
                      :reduce='item => item.id'
                      :placeholder="$t('services')"
                      :multiple='true'
                  >
                    <template #no-options>{{ $t('No options') }}</template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
                rules='required'
              >
                <ImagePreview
                  v-model='form.image'
                  :label="$t('labels.image')"
                  />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>




            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import { BFormRadio } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { BFormRadio },
  mixins: [formMixin],
  data() {
    return {

      services:[],

      form: {
        sort: null,
        name: {
          en: null,
          ar: null,
        },


        services: [],


        image: null,
        status: 1,

      },

       isDataLoaded: false,

    }
  },



  computed: {
    toRedirectResource() {
      return 'service-provider'
    },
  },
  created() {

    this.axios.get(`/model-list/services`)
        .then(res => {
          this.services = res.data.data
        });

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            status: data.status,
            image: data.image,
            services:data.services,
          }


          this.isDataLoaded = true
        })
    },


  },

}
</script>


